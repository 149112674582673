<script setup lang="ts">
/* Computed variables */

/**
 * Should a submenu be displayed.
 */
const showSubmenu: ComputedRef<boolean> = computed(() => !!useCommonStore().submenu);
</script>

<template>
    <div id="app">
        <Sidebar/>

        <Submenu v-if="showSubmenu"/>

        <div v-else id="submenu-spacer"/>

        <div id="container">
            <div id="content" :class="{ 'reverse-left-padding': showSubmenu }">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style scoped>
    #app {
        display: flex;
        width: 100vw;
        height: 100vh;
    }

    #container {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow-y: scroll;
    }

    #content {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        width: 100%;
        max-width: 1100px;
        height: fit-content;
        min-height: 100%;
        padding: 2.5rem 3.75rem;
        gap: 2.5rem;
    }

    /* 1920px */
    @media screen and (width >= 120em) {
        #content {
            max-width: 980px;
            padding: 2.5rem 0;
        }

        #submenu-spacer {
            flex-shrink: 0;
            width: 10rem;
        }
    }
</style>
