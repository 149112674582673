<script setup lang="ts">
import DialogSwitchAccount from '~/components/dialog/DialogSwitchAccount.vue';

/* Pinia stores */

/**
 * The auth Pinia store.
 */
const authPinia = useAuthStore();

/**
 * The account Pinia store.
 */
const accountStore = useAccountStore();

/* Component variables */

/**
 * The toggle menu HTML button element.
 */
const toggleButton: Ref<HTMLButtonElement | null> = ref(null);

/**
 * The menu HTML dialog element.
 */
const menu: Ref<HTMLDivElement | null> = ref(null);

/**
 * Is the menu open.
 */
const isOpen: Ref<boolean> = ref(false);

/**
 * The switch account dialog.
 */
const switchAccountDialog: Ref<typeof DialogSwitchAccount | null> = ref(null);

/* Computed variables */

/**
 * The currently authenticated user.
 */
const user: ComputedRef<AuthUser | null> = computed((): AuthUser | null => authPinia.user);

/* Component methods */

/**
 * Show the menu.
 */
const showMenu = (): void => {
    isOpen.value = true;

    // Add the event listener to listen for clicks outside the toggle button
    document.addEventListener('click', listenForClick);
};

/**
 * Close the menu.
 */
const hideMenu = (): void => {
    isOpen.value = false;

    // Remove the event listener to listen for clicks outside the toggle button
    document.removeEventListener('click', listenForClick);
};

/**
 * Toggle the menu's state.
 */
const toggleMenu = (): void => {
    if (isOpen.value) {
        hideMenu();
    } else {
        showMenu();
    }
};

/**
 * Listen for a click event and close the menu on trigger.
 */
const listenForClick = (event: Event): void => {
    // If the toggle button is set (needed to handle events triggering before the button is mounted)
    if (toggleButton.value) {
        const target = event.target as Element;

        // If the event is inside the toggle button
        if (toggleButton.value.contains(target)) {
            // Ignore the event as the button handles its own events
            return;
        }
    }

    // If the menu property is set
    if (menu.value) {
        // Close the menu
        hideMenu();
    }
};

/**
 * Open the switch account dialog.
 */
const openSwitchAccountDialog = (): void => {
    (switchAccountDialog.value as typeof DialogSwitchAccount).showDialog();
};
</script>

<template>
    <div v-if="user" id="user-menu-container" class="w-full">
        <button id="user-menu-button" type="button" class="button button-no-style"
                ref="toggleButton" @click="toggleMenu">
            <div class="flex-none">
                <SvgUser/>
            </div>

            <div class="flex flex-col overflow-hidden">
                <span v-if="accountStore.account" class="truncate text-left">
                    {{ accountStore.account.name }}
                </span>

                <span id="username" class="font-normal text-xs truncate text-left">
                    {{ (user as AuthUser).email ?? (user as AuthUser).username }}
                </span>
            </div>

            <div class="h-full flex items-center flex-none">
                <SvgOverflowMenuButton/>
            </div>
        </button>

        <div v-if="isOpen" ref="menu" id="user-menu">
            <div id="user-menu-content">
                <NuxtLink v-if="(user as AuthUser).is_admin" :to="`${adminDashboardUrl()}/accounts`"
                          class="button button-overflow-menu button-overflow-menu-secondary">
                    Back to admin dashboard
                </NuxtLink>

                <div v-else>
                    <button v-if="(user as AuthUser).email" type="button"
                            class="button button-overflow-menu button-overflow-menu-secondary"
                            @click="openSwitchAccountDialog">
                        <SvgRotate/>

                        Switch account
                    </button>

                    <NuxtLink to="/auth/settings"
                              class="button button-overflow-menu button-overflow-menu-secondary">
                        <SvgUserSettings/>

                        User settings
                    </NuxtLink>
                </div>

                <button type="button"
                        class="button button-overflow-menu button-overflow-menu-danger"
                        @click="authPinia.logout">
                    <SvgLogout/>

                    Log out
                </button>
            </div>

            <!-- Arrow below menu -->
            <div class="user-menu-arrow"/>
            <div class="user-menu-arrow-shadow-cover"/>
        </div>
    </div>

    <DialogSwitchAccount v-if="user" ref="switchAccountDialog" redirect-url="/"/>
</template>

<style scoped>
    #user-menu-container {
        display: flex;
        position: relative;
        justify-content: center;
        padding: 0 3.75rem;
    }

    #user-menu-button {
        display: flex;
        align-items: center;
        max-width: 100%;
        height: 2.5rem;
        color: var(--grey-500);
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.0125rem;
        line-height: 1.5rem;
        gap: 0.75rem;
    }

    #username {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    #user-menu {
        display: flex;
        position: absolute;
        z-index: 1;
        right: 12px;
        bottom: 48px;
        padding: 0.75rem;
        border: 1px solid var(--grey-800);
        border-radius: 0.75rem;
        background: #FFF;
        box-shadow: 0 4px 13px 0 rgb(160 172 218 / 22%);
    }

    #user-menu-content {
        display: flex;

        /* Ensure the user menu content is not covered by the arrow shadow cover div */
        z-index: 1;
        flex-direction: column;
        gap: 0.25rem;
    }

    .user-menu-arrow {
        position: absolute;
        right: 42px;
        bottom: -7px;
        width: 14px;
        height: 14px;
        transform: rotate(45deg);
        border: 1px solid var(--grey-800);
        background: #FFF;
        box-shadow: 0 0 13px rgb(160 172 218 / 22%);
    }

    /* Cover the arrow div's shadow inside the user menu rectangle */
    .user-menu-arrow-shadow-cover {
        position: absolute;
        right: 28px;
        bottom: 0;
        width: 40px;
        height: 28px;
        background: #FFF;
    }
</style>
