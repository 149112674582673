<script setup lang="ts">
/* Pinia stores */

/**
 * The account Pinia store.
 */
const accountStore = useAccountStore();

/* Computed variables */

/**
 * The current partner.
 */
const partner: ComputedRef<Partner> = computed((): Partner => (useCommonStore().partner));

/**
 * The current account.
 */
const account: ComputedRef<Account> = computed((): Account => (accountStore.account as Account));
</script>

<template>
    <template v-if="account">
        <nav id="sidebar">
            <section id="sidebar-top-section">
                <div id="sidebar-logo-container">
                    <img id="sidebar-logo" :src="partner.logo_url" :alt="partner.name + ' logo'"/>
                </div>

                <section id="sidebar-links">
                    <SidebarLink to="/products" text="Products">
                        <SvgProducts class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/enhancements" text="Enhancements">
                        <SvgEnhancements class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/discounts" text="Discounts">
                        <SvgDiscounts class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/stores"
                                 :text="account.has_multiple_stores ? 'Stores' : 'Store'">
                        <SvgStores class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/categories" text="Categories">
                        <SvgCategories class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/orders" text="Orders">
                        <SvgOrders class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/reports" text="Reports">
                        <SvgReports class="sidebar-link-icon"/>
                    </SidebarLink>

                    <SidebarLink to="/settings" text="Settings">
                        <SvgSettings class="sidebar-link-icon"/>
                    </SidebarLink>
                </section>

                <div class="terminal-link-container">
                    <NuxtLink to="/terminal/redeem" class="button button-outline-primary">
                        Redeem
                    </NuxtLink>
                </div>
            </section>

            <SidebarUserMenu/>
        </nav>
    </template>
</template>

<style scoped>
    #sidebar {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        width: 18.75rem;
        padding: 3rem 0 2.5rem;
        border-radius: 0 2.5rem 2.5rem 0;
        background: #FFF;
    }

    #sidebar-logo-container {
        display: flex;
        flex-direction: column;
        height: 3.125rem;
        padding: 0 60px;
    }

    #sidebar-logo {
        max-height: 100%;
    }

    #sidebar-top-section {
        display: flex;
        flex-direction: column;
        gap: 3.5rem;
    }

    #sidebar-links {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
    }

    .sidebar-link-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .terminal-link-container {
        display: flex;
        margin: 0 2.5rem;
    }

    .terminal-link-container > a {
        width: 100%;
        height: 2.75rem;
    }
</style>
